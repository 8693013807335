import { Component, HostListener, OnDestroy, OnInit, inject } from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { MessageService, PrimeNGConfig } from 'primeng/api';
import { GlobalState } from './core/global-state/app.reducer';
import * as responsiveActions from './core/global-state/responsive/responsive.actions'
import * as devicesActions from './core/global-state/device/device.actions'
import { first, lastValueFrom, map, Observable, Subject, Subscription, tap } from 'rxjs';
import { ErrorsManager } from './core/errors/errors.manager';
import { GoogleTagManager } from './configs/libraries/google/google-tag-manager/google-tag-manager';
import { AllowedLanguages, LanguagesForTranslate } from './configs/libraries/ngx-translate/constants/languages.constants';
import { ILanguage } from './modules/users/modules/profile/models/interfaces/language.interface';
import { IDiscordData } from './modules/users/modules/profile/models/interfaces/user.interface';
import { LayoutManager } from './layouts/layout.manager';

// Actions
import * as languagesActions from './core/global-state/language/language.actions'
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { DeviceDetectorService } from 'ngx-device-detector';
import { TypesDevices } from './core/global-state/device/constants/types.constants';
import { RolesAccount } from './pages/auth/interfaces/auth.constant';
import { DeviceSO } from './configs/libraries/ngx-device-detector/constants/devices.constants';
import { LINKS } from './shared/models/constants/links.contants';
import { ResponsiveState } from '@core/global-state/responsive/responsive.reducer';
import { SizesDevice } from '@shared/models/constants/global.constants';
import { AuthFunctions } from '@pages/auth/auth.functions';
import { SentryService } from 'src/app/configs/libraries/sentry/sentry.service';
import { DynamicFontLoaderService } from '@core/services/dynamic-font-loader.service';
import { FreshChatFunctions } from 'src/app/configs/libraries/fresh-chat/fresh-chat.functions';
import { C_TOAST_KEYS } from '@shared/primeng/constants/toast.keys';
import { selectLoader } from '@core/global-state/loader/loader.selectors';
import { FeatureFlagsManager } from '@core/features-flags/feature-flags.manager';
import { CFeatures } from '@core/features-flags/models/constants/features-flags.constants';
import { selectDevices } from '@core/global-state/device/device.selectors';
import { WafProtectionService } from '@core/services/waf-protection/waf-protection.service';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  providers: [MessageService,ErrorsManager]
})
export class AppComponent implements OnInit, OnDestroy {

    //#region [---- [DEPENDENCIES] ----]
    private readonly primengConfig: PrimeNGConfig = inject(PrimeNGConfig);
    private readonly translate: TranslateService = inject(TranslateService);
    private readonly googleTagManager:GoogleTagManager = inject(GoogleTagManager);
    private readonly gtmService: GoogleTagManagerService = inject(GoogleTagManagerService);
    private readonly store: Store<GlobalState> = inject(Store);
    private readonly layoutManager:LayoutManager = inject(LayoutManager);
    private readonly deviceService: DeviceDetectorService = inject(DeviceDetectorService);
    private readonly sentryService: SentryService = inject(SentryService);
    private readonly authFunctions: AuthFunctions = inject(AuthFunctions);
    private readonly fontLoaderService: DynamicFontLoaderService = inject(DynamicFontLoaderService);
    private readonly freshChatFunctions: FreshChatFunctions = inject(FreshChatFunctions);
    private readonly featureFlagsManager: FeatureFlagsManager = inject(FeatureFlagsManager);
    private readonly wafProtectionService: WafProtectionService = inject(WafProtectionService)
    //#endregion

    title = 'Modak Makers';

    //Translate
    languages: ILanguage[]

    // Spinner
    inProgress: boolean = false
    loaderSubscription!:Subscription

    // Components
    containerClass$ =  new Subject<boolean>()
    layoutParent$ =  new Subject<boolean>()
    showMenuComponent$ =  new Subject<boolean>()
    showHeaderComponent$ =  new Subject<boolean>()

    private readonly subscriptions = new Subscription()
    // Menu
    showPanelMenu: boolean = false
    isAdminMenu$ =  new Subject<boolean>()
    menuParents$ =  new Subject<boolean>()

    // Menu Parent
    showPanelMenuParent:boolean = false

    rolSubscription: Subscription;
    rolActive: typeof RolesAccount[keyof typeof RolesAccount]

    //------------------------------------------------------

    // Discord login
    discordUser: IDiscordData
    discordSubscription: Subscription
    hasDiscordUser: boolean = false

    // Freshchat
    isFreshChatTimeOut$:Observable<boolean>;
    freshChatEnabled = false
    public showButtonChat$ = new Subject<boolean>()

    userSubscription: Subscription;
    userIsLogged:boolean;

    // Size Detection (Responsive)
    isMobile: boolean = false
    responsiveSubscription: Subscription;

    //TOASTS
    public TOAST_KEYS = C_TOAST_KEYS

    //WEBVIEW
    isWebView$ :Observable<boolean> =this.store.select(selectDevices).pipe(
      map((devices) => devices?.isWebView ?? false),
    )

  // Detection change resize devices
  @HostListener('window:resize', ['$event'])
  onResize(event:any) {
      const sizeDeviceEvent = event.target.innerWidth;
      sizeDeviceEvent < 960 ? this.store.dispatch(responsiveActions.isMobile()) : this.store.dispatch(responsiveActions.isDesktop())
  }


  //#region [---- LIFE CYCLES ----]
  ngOnInit(): void {
    this.gtmService.addGtmToDom()
    this.setLanguage()
    this.fontLoaderService.loadFonts();
    this.checkResponsive()
    this.checkTokenForFreshChatAlternative()
    this.detectDeviceForFreshChat()
    this.detectDeviceForWebView()
    this.loadWafProtection()
    this.checkRol()
    this.initConfigFeatures()
    this.spinnerSubscription()
    this.howOrHideComponents()
    this.setUserLogsManager()
    this.layoutManager.toggleChatButton(true)
  }

  ngOnDestroy(): void {
    if(this.loaderSubscription) this.loaderSubscription.unsubscribe()
    if(this.discordSubscription) this.discordSubscription.unsubscribe()
    if(this.userSubscription) this.userSubscription.unsubscribe()
    if(this.responsiveSubscription) this.responsiveSubscription.unsubscribe()
    if(this.subscriptions) this.subscriptions.unsubscribe()
    this.layoutManager.toggleChatButton(false)
    this.unSubscribeAll()
  }
  //#endregion

  checkRol():void{
    this.rolSubscription = this.store.select('authentication').pipe(
      map(user => user?.user?.role || RolesAccount.TEEN),
    ).subscribe(rol => this.rolActive = rol)
  }

  checkTokenForFreshChatAlternative():void{
    // Only for logic replace freshchat
    this.userSubscription = this.store.select('authentication').pipe(
      map(auth => auth.user?.accessToken)
    ).subscribe(token => this.userIsLogged = !!token)
  }

  detectDeviceForFreshChat():void {
    this.isFreshChatTimeOut$ = this.freshChatFunctions.freshChatTimeout$
    const isIOS = this.deviceService.os === DeviceSO.IOS
    const isMobile = this.deviceService.isMobile()
    const supported: boolean = !(isIOS && isMobile);

    const sub = this.featureFlagsManager.observeFeatureEnabled(CFeatures.SUPPORT_CHAT)
    .pipe(
      tap(isEnabled=>this.freshChatEnabled = isEnabled && supported)
    ).subscribe()
    this.subscriptions.add(sub)
  }

  async detectDeviceForWebView():Promise<void>{

    const isWebView = await lastValueFrom(this.store.select('devices').pipe(
      first(),
      map(devices => devices?.isWebView),
    )) 

    const typeDevice = (this.deviceService.getDeviceInfo()).deviceType.toUpperCase();

    if(!isWebView && typeDevice === TypesDevices.MOBILE) this.store.dispatch(devicesActions.isDesktopDevice())

    if(typeDevice === TypesDevices.DESKTOP) this.store.dispatch(devicesActions.isDesktopDevice())

  }

  initConfigFeatures():void {
    // Detect size device init
    window.innerWidth < 960 ? this.store.dispatch(responsiveActions.isMobile()) : this.store.dispatch(responsiveActions.isDesktop())

    // Ripple for init prime ng
    this.primengConfig.ripple = true;

    // Google tag manager
    this.googleTagManager.pushGTMDataLayerForEveryVisitedPage()
  }

  // Initial Language or Active
  async setLanguage():Promise<void> {
    const languages:string[] = Object.keys(LanguagesForTranslate).reduce((accumulator,current) => {
      accumulator = [...accumulator,LanguagesForTranslate[current]]
      return accumulator
    },[])   
    
    this.translate.addLangs(languages);

    // this language will be used as a fallback when a translation isn't found in the current language
    this.translate.setDefaultLang(LanguagesForTranslate.ENGLISH);

    // the lang to use, if the lang isn't available, it will use the current loader to get them
    const stateLanguage = await lastValueFrom(this.store.select('language').pipe(
      first(),
      map(language => language?.active),
    )) 
    
    if(!stateLanguage){
      this.translate.use(LanguagesForTranslate.ENGLISH);
      this.store.dispatch(languagesActions.setLanguage({language:AllowedLanguages.ENGLISH}))
    } else this.translate.use(LanguagesForTranslate[stateLanguage])
  }

  private howOrHideComponents():void{
    this.containerClass$ = this.layoutManager.containerClass
    this.layoutParent$ = this.layoutManager.layoutParent
    this.showHeaderComponent$ = this.layoutManager.showHeaderComponent
    this.showMenuComponent$ = this.layoutManager.showPanelMenu
    this.isAdminMenu$ = this.layoutManager.isAdminMenu
    this.menuParents$ = this.layoutManager.isParentMenu
    this.showButtonChat$ = this.layoutManager.showChatButton
  }

  checkResponsive(): void {
    this.responsiveSubscription = this.store.select((state: GlobalState) => state.responsive)
      .subscribe((responsiveState: ResponsiveState) => {
        this.isMobile = responsiveState.sizeDevice === SizesDevice.MOBILE;
    });
  }

  spinnerSubscription():void{
      this.loaderSubscription = this.store.select(selectLoader).subscribe(spinner => this.inProgress = spinner);
  }

  unSubscribeAll():void{
    this.containerClass$.unsubscribe()
    this.showHeaderComponent$.unsubscribe()
    this.showMenuComponent$.unsubscribe()
    this.isAdminMenu$.unsubscribe()
  }

  goExternalSupport():void {
    window.open(LINKS.MODAK_SUPPORT, "_blank")
  }

  private setUserLogsManager(): void {
    this.authFunctions.getUser().pipe(first()).subscribe(user => {
      if (user) {
          this.sentryService.setUser(user)
      }
    });
  }

  private loadWafProtection(): void {
    const sub = this.featureFlagsManager.observeFeatureEnabled(CFeatures.WAF_PROTECTION)
    .pipe(
      tap(isEnabled => {
        if (isEnabled) this.wafProtectionService.loadScript()
      })
    ).subscribe()
    this.subscriptions.add(sub)
  }

}
