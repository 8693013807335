import { HttpClient, HttpContext, HttpErrorResponse } from "@angular/common/http";
import { Injectable, inject } from "@angular/core";
import { catchError, Observable, tap, throwError } from "rxjs";
import { environment } from "src/environments/environment";
import {
  IResendCodeVerify,
  IUserSignUpParent,
  IUserSignUpTeen,
  IuserSignin,
  IuserVerification
} from "./interfaces/auth.interfaces";
import {
  IAuthResponse,
  IRefreshRequest,
  IRefreshResponse,
  IVerifyResponse,
} from "./interfaces/auth.responses";
import {
  HIDE_SPINNER,
  NO_INSERT_TOKEN,
} from "src/app/core/interceptors/constants/interceptors.constants";
import { PROJECT_TAGS } from "src/app/configs/libraries/tracking-manager/constants/projects.constants";
import { IEventBaseDataSchema } from "src/app/configs/libraries/tracking-manager/interfaces/tracking-manager.interfaces";
import { TrackingManager } from "src/app/configs/libraries/tracking-manager/tracking-manager";
import { AuthUtils } from "src/app/modules/authentication/authentication.utils";
import { SECOND } from "@shared/models/constants/time.constant";
import { AUTH_EVENTS } from "@pages/auth/auth-parent/models/constants/auth-parent.events.constants";

@Injectable({
  providedIn: "root",
})
export class AuthApi {

  //#region [---- [DEPENDENCIES] ----]
  private readonly http: HttpClient = inject(HttpClient);
  private readonly authUtils: AuthUtils = inject(AuthUtils);
  private readonly trackingManager: TrackingManager = inject(TrackingManager);
  //#endregion

  //#region  [---- [PROPERTIES] ----]
  private BASE_URL: string = environment.APIS.MONOLITH_URL;;
  private USERS_DOMAIN_URL: string = environment.APIS.USERS_DOMAIN_URL;

  //#endregion

  /**
   * @deprecated The method should not be used
   */
  signUpParent(user: IUserSignUpParent): Observable<IAuthResponse> {
    const options = {
      context: new HttpContext().set(HIDE_SPINNER, true),
    };
    return this.http.post<IAuthResponse>(`${this.USERS_DOMAIN_URL}/auth/v1/signup`, user, options);
  }

  signUpParentUser(user: IUserSignUpParent): Observable<IAuthResponse> {
    const options = {
      context: new HttpContext().set(HIDE_SPINNER, true),
    };
    return this.http.post<IAuthResponse>(`${this.USERS_DOMAIN_URL}/auth/v1/parent`, user, options);
  }

  /**
   * @deprecated The method should not be used
  */
  signUpTeen(user: IUserSignUpTeen): Observable<IAuthResponse> {
    const options = {
      context: new HttpContext().set(HIDE_SPINNER, true),
    };
    return this.http.post<IAuthResponse>(`${this.USERS_DOMAIN_URL}/users/v1/signup`, user, options);
  }

  /**
   * @deprecated The method should not be used
  */
  signIn(user: IuserSignin): Observable<IAuthResponse> {
    const options = {
      context: new HttpContext().set(HIDE_SPINNER, true),
    };
    return this.http.post<IAuthResponse>(`${this.USERS_DOMAIN_URL}/users/v1/signin`, user, options);
  }

  signInUser(user: IuserSignin, hideSpinner: boolean = false): Observable<IAuthResponse> {
    const options = {
      context: new HttpContext().set(HIDE_SPINNER, hideSpinner),
    };
    return this.http.post<IAuthResponse>(`${this.USERS_DOMAIN_URL}/auth/v1/signin`, user, options);
  }

  /**
   * @deprecated The method should not be used
  */
  verify(verify: IuserVerification, hideSpinner: boolean = false): Observable<IVerifyResponse> {
    const options = {
      context: new HttpContext().set(HIDE_SPINNER, hideSpinner),
    };
    return this.http.post<IVerifyResponse>(`${this.BASE_URL}/users/verify`, verify, options);
  }

  verifySignIn(verify: IuserVerification, hideSpinner: boolean = false): Observable<IVerifyResponse> {
    const options = {
      context: new HttpContext().set(HIDE_SPINNER, hideSpinner),
    };
    return this.http.post<IVerifyResponse>(`${this.USERS_DOMAIN_URL}/auth/v1/verify`, verify, options);
  }

  verifySignUp(verify: IuserVerification, hideSpinner: boolean = false): Observable<IVerifyResponse> {
    const options = {
      context: new HttpContext().set(HIDE_SPINNER, hideSpinner),
    };
    return this.http.post<IVerifyResponse>(`${this.USERS_DOMAIN_URL}/auth/v1/verify_signup`, verify, options);
  }

  resendCodeVerify(resend: IResendCodeVerify, hideSpinner: boolean = false): Observable<IVerifyResponse> {
    const options = {
      context: new HttpContext().set(HIDE_SPINNER, hideSpinner),
    };
    return this.http.post<IResendCodeVerify>(`${this.USERS_DOMAIN_URL}/auth/v1/verify_resend`, resend, options);
  }

  refreshTokenUser(body: IRefreshRequest): Observable<IRefreshResponse> {
    const options = {
      context: new HttpContext().set(NO_INSERT_TOKEN, true),
    };

    return this.http.post<IRefreshResponse>(`${this.USERS_DOMAIN_URL}/auth/v1/refresh`, body, options).pipe(
      tap((response) => {
        const tokenDecoded = this.authUtils.getTokenDecoded(response.access)
        const expirationJWT = tokenDecoded?.exp * SECOND
        const now = new Date().getTime();

        const dataEvent:IEventBaseDataSchema = {
          Token:{
            expiration:{
              now: new Date(),
              date: expirationJWT,
              relative : expirationJWT - now
            }
          },
          project_tag: PROJECT_TAGS.ACCOUNT_ACCESS
        }

        this.trackingManager.trackEventV2(AUTH_EVENTS.TOKEN_EXPIRATION_DATE,dataEvent);
      }),
      catchError((error: HttpErrorResponse) => {

        const dataEvent:IEventBaseDataSchema = {
          Token:{
            expiration:{
              now: new Date(),
            },
            errors:{
              modak_errors: error?.error,
              modak_errors_string: error?.error?.toString()
            },
            original_status: error?.status?.toString(),
            status: error?.status,
            request_id: error?.headers?.get('x-amzn-requestid'),
            base_query_meta: error?.url
          },
          project_tag: PROJECT_TAGS.ACCOUNT_ACCESS
        }

        this.trackingManager.trackEventV2(AUTH_EVENTS.TOKEN_EXPIRATION_ERROR,dataEvent);


        return throwError(() => error);
      })
    )
  }
}

