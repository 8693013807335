import { WebviewManager } from '@core/services/webview-manager/webview-manager';
import { inject, Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { Store } from "@ngrx/store";
import {
  catchError,
  filter,
  first,
  firstValueFrom,
  map,
  Observable,
  of,
  switchMap,
  tap,
  throwError,
} from "rxjs";
import { GlobalState } from "@core/global-state/app.reducer";
import { IRefreshRequest } from "./interfaces/auth.responses";
import * as userActions from "./state/authentication.actions";
import { logoutAction } from "../../core/global-state/clear/clearState.actions";
import * as responsiveActions from "../../core/global-state/responsive/responsive.actions";
import { environment } from "src/environments/environment";
import {
  ITokensUser,
  IUser,
} from "./interfaces/auth.interfaces";
import { AuthApi } from "./auth.api";
import { IUserTrack } from "src/app/configs/libraries/tracking-manager/interfaces/tracking-manager.interfaces";
import {
  selectAllTokens,
  selectRefreshToken,
  selectToken,
  selectUser,
} from "@pages/auth/state/authentication.selectors";
import { SentryService } from "src/app/configs/libraries/sentry/sentry.service";

import { RedirectionManager } from "@core/redirection/redirection.manager";
import { AuthUtils } from "src/app/modules/authentication/authentication.utils";
import { SegmentService } from "src/app/configs/libraries/tracking-manager/segment/segment.service";
import { SECOND } from "@shared/models/constants/time.constant";
import { AuthAwsAmplifyService } from "src/app/configs/libraries/aws-amplify/aws-amplify.service";
import { FeatureFlagsManager } from "@core/features-flags/feature-flags.manager";
import { CFeatures } from "@core/features-flags/models/constants/features-flags.constants";
import { CTIME_OUT } from './auth-parent/models/constants/auth-parent.contants';

declare var ReactNativeWebView

@Injectable({
  providedIn: "root",
})
export class AuthFunctions {
  //#region [---- [PROPERTIES] ----]
  private isProduction: boolean = environment.production || false;
  private readonly MODAK_URL: string = environment.MODAK_URL;
  //#endregion

  //#region [---- [DEPENDENCIES] ----]
  private readonly route: Router = inject(Router);
  private readonly store: Store<GlobalState> = inject(Store);
  private readonly authApi: AuthApi = inject(AuthApi);
  private readonly authAwsAmplifyService: AuthAwsAmplifyService = inject(AuthAwsAmplifyService);
  private readonly sentryService: SentryService = inject(SentryService);
  private readonly redirectionManager: RedirectionManager = inject(RedirectionManager);
  private readonly authUtils: AuthUtils = inject(AuthUtils);
  private readonly segmentService: SegmentService = inject(SegmentService);
  private readonly featureFlagsManager: FeatureFlagsManager = inject(FeatureFlagsManager)
  private readonly webviewManager: WebviewManager = inject(WebviewManager)
  //#endregion

  //#region [---- [LOGIC] -----]
  public logout(refresh?: boolean, isParent?: boolean): void {
    this.clearData()


    if (!this.isProduction && !refresh) {
      this.goToAuth(isParent);
      return;
    }

    if(this.webviewManager.isWebViewActive()) {
      if (typeof ReactNativeWebView !== "undefined" &&  ReactNativeWebView) {
        ReactNativeWebView.postMessage(JSON.stringify({
          status: CTIME_OUT,
        }));
      }
    }
    



    this.authAwsAmplifyService.handleSignOut().pipe(
      catchError(() => of(null))
    ).subscribe(() => {
      refresh ? this.goToAuth() : (window.location.href = this.MODAK_URL);
    })
  }

  public clearData(): void {
    this.store.dispatch(logoutAction());
    this.store.dispatch(
      window.innerWidth < 960
        ? responsiveActions.isMobile()
        : responsiveActions.isDesktop()
    );

    localStorage.clear();
    sessionStorage.clear();
    this.sentryService.clearUser();
    this.redirectionManager.cleanRedirection()
    this.segmentService.reset()
    
  }

  private goToAuth(isParent?: boolean): void {
    this.route.navigate([`/auth${isParent ? "/parents" : ""}`], {
      queryParams: { signin: true },
    });
  }

  private getAllTokensFromStore(): Observable<ITokensUser> {
    return this.store.select(selectAllTokens).pipe(first());
  }

  public getUser(): Observable<IUser> {
    return this.store.select(selectUser);
  }

  public getUserTrack(): Observable<IUserTrack> {
    return this.getUser().pipe(
      filter(Boolean),
      map((user) => {
        let tokenUserId = null;
        if (user && user.accessToken) {
            const tokenDecoded = this.authUtils.getTokenDecodedUser(user.accessToken);
            tokenUserId = tokenDecoded.user_uuid
        }
        return {
          uuid: user?.id ?? tokenUserId,
          email: user?.email,
          phone: user?.phone,
          type: user.role,
          name: user?.name
        };
      })
    );
  }

  public async getToken(): Promise<string | void> {
    // get tokens from the store
    const tokensStore = await firstValueFrom(this.getAllTokensFromStore());

    if (!tokensStore || !tokensStore.accessToken) return "";

    const isExpired: boolean = await this.verifyExpiredToken(
      tokensStore.accessToken
    );
    if (!isExpired) return tokensStore.accessToken;

    const newAccessToken = await this.getNewAccessToken(tokensStore.refreshToken);
    return newAccessToken;
  }

  public isAuthenticated(): Observable<boolean> {
    return this.store.select(selectToken).pipe(
      first(),
      map((token) => Boolean(token))
    );
  }

  private async verifyExpiredToken(token: string): Promise<boolean> {
    const tokenDecoded = this.authUtils.getTokenDecoded(token)
    if (!tokenDecoded) return true

    const tokenDateExp: number = tokenDecoded.exp * SECOND;
    const now = Date.now();

    return now >= tokenDateExp;
  }

  private async getNewAccessToken(refreshToken: string): Promise<string | void> {
    const isEnabled = this.featureFlagsManager.isFeatureEnabled(CFeatures.AUTHENTICATION_V3)

    try {
      const getTokenPromise = isEnabled ? firstValueFrom(this.authAwsAmplifyService.getCurrentSession(true)) : this.getNewToken(refreshToken)
      const newAccessToken: string = await getTokenPromise

      this.store.dispatch(userActions.refreshAccessToken({ newToken: newAccessToken }));
      return newAccessToken;
    } catch (error) {
      this.logout(true);
    }
  }

  private getNewToken(refreshToken:string): Promise<string> {
    const body: IRefreshRequest = {
      refresh_token: refreshToken,
      refresh: refreshToken
    };
    return firstValueFrom(this.authApi.refreshTokenUser(body).pipe(map((response) => response.access)))
  }

  public getProcessToken(): Observable<string> {
    return of("").pipe(
      switchMap(() => {
        return this.store.select(selectRefreshToken);
      }),
      map((refreshToken) => {
        return { refresh_token: refreshToken, refresh: refreshToken };
      }),
      switchMap((body) => {
        return this.authApi.refreshTokenUser(body).pipe(
          tap((data) => {
            this.store.dispatch(userActions.refreshAccessToken({ newToken: data.access }));
          }),
          map((response) => response.process),
          catchError((error) => {
            this.logout(true);
            return throwError(() => error);
          })
        );
      })
    );
  }

  //#endregion
}
