export const USA_COUNTRY_CODE = 'US' as const;
export const ARG_COUNTRY_CODE = 'AR' as const;
export const COL_COUNTRY_CODE = 'CO' as const;
export const IND_COUNTRY_CODE = 'IN' as const;
export const PE_COUNTRY_CODE = 'PE' as const;
export const BR_COUNTRY_CODE = 'BR' as const;
export const MX_COUNTRY_CODE = 'MX' as const;

export const ALLOWED_COUNTRIES_CODES_TEENS = [
    USA_COUNTRY_CODE,
    ARG_COUNTRY_CODE,
    BR_COUNTRY_CODE,
    COL_COUNTRY_CODE,
    IND_COUNTRY_CODE,
    MX_COUNTRY_CODE,
    PE_COUNTRY_CODE
];
export const ALLOWED_COUNTRIES_CODES_PARENT = [USA_COUNTRY_CODE];
export const ALLOWED_COUNTRIES_CODES_SIGNIN = [
    USA_COUNTRY_CODE, 
    ARG_COUNTRY_CODE, 
    COL_COUNTRY_CODE,
    IND_COUNTRY_CODE,
    PE_COUNTRY_CODE
];

export const TYPE_INPUT_NUMERIC = 'numeric' as const;

export const INPUT_PHONE_NUMBER_MASK = '(000) 000-0000' as const;
export const LABEL_PHONE_NUMBER_MASK = '+0 (000) 000-0000' as const;

export const PhoneNumberRegExp = {
    NUMBER_PASTE: /^[0-9]*$/,
    DIGITS: /\D/g,
    MASK_CHARS: /\(|\)|\d/g,
} as const;

export const KEYBOARD_KEY_CODE = {
    ZERO_KEY: 48,
    NINE_KEY: 57,
    BACKSPACE_KEY: 8,
    V_KEY: 86,
    FUNCTION_KEYS: 0,
} as const;

export const C_NUMBERS_ALLOWED_DEV = [
    '+1112223333',
    '+1112224444',
    '+1112225555'
]