import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'formatMoCardText'
})
export class FormatMoCardTextPipe implements PipeTransform {

  transform(value: string): string {
    let words = value.split('_');
    words = words.map(word => word.charAt(0).toUpperCase() + word.slice(1));
    return words.join('');
  }

}