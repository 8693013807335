import { HttpRequest } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { AppFunctions } from '@shared/utils/app.functions';
import { CookieService } from 'ngx-cookie-service';
import { environment } from 'src/environments/environment';

declare var AwsWafIntegration: any;

@Injectable({
  providedIn: 'root'
})
export class WafProtectionService {

  private appFunctions: AppFunctions = inject(AppFunctions);
  private cookieService: CookieService = inject(CookieService)

  private countWafError: number = 0;
  private isProd: boolean = environment.production;

  public loadScript(): void {
    const src = `https://${environment.WAF.IDENTIFIER}.${environment.WAF.REGION}.sdk.awswaf.com/${environment.WAF.IDENTIFIER}/${environment.WAF.TOKEN}/challenge.js`
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = src;
    script.defer = true;

    document.head.appendChild(script);
  }

  public async getToken(): Promise<string> {
    if (typeof AwsWafIntegration !== 'undefined' && AwsWafIntegration) {
      const awsToken = await AwsWafIntegration.getToken();
      if (awsToken) {
        return awsToken;
      }
    }
    return this.getTokenFromCookie()
  }

  private getTokenFromCookie(): string {
    return this.cookieService.get('aws-waf-token')
  }

  public incrementWafCount(): void {
    this.countWafError++;
  }

  public resetWafCount(): void {
    this.countWafError = 0;
  }

  public getCountError(): number {
    return this.countWafError;
  }

  public isModakUrl(request: HttpRequest<any>): boolean {
    return request.url.includes('.modak.live')
  }

  public createRequestWithAwsToken(
    request: HttpRequest<any>, 
    awsToken: string
  ): HttpRequest<any> {
    return request.clone({
        setHeaders: {
            'x-aws-waf-token': awsToken
        }
    });
  }

  public createRequestForSkipWaf(request: HttpRequest<any>): HttpRequest<any> {
    return request.clone({
        setHeaders: {
            'x-modak-skip-waf': environment.WAF.SKIP_KEY
        }
    });
  }

  public canSkipWaf(request: HttpRequest<any>): boolean {
    if(this.isProd) return false;
    return this.isModakUrl(request) && this.appFunctions.isLocalhost()
  }
}
