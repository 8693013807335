<section class="item">

    <!-- Image -->
    <mdk-icon-profile *ngIf="transaction?.transaction_type !== creditTransaction; else isCreditTransaction"
        [imageURL]="'shopping_bag.svg' | staticServe"
        [type]="transaction?.transaction_type"
        [name]="transaction.toName"
    >
    </mdk-icon-profile>

    <ng-template #isCreditTransaction>
        <mdk-icon-profile
            [type]="transaction.transaction_type"
            [name]="transaction.sender"
        >
        </mdk-icon-profile>
    </ng-template>

    <!-- Data -->
    <div class="transaction-info-container transaction-status-{{transaction?.status}}">
        <div class="data-transaction">
            <h4 class="label label-{{transaction?.status}}" *ngIf="transaction?.transaction_type === refundTransaction">{{"PARENT_PORTAL.TRANSACTIONS.REFUND_FOR" | translate}} {{transaction.sender}}</h4>
            <h4 class="label label-{{transaction?.status}}" *ngIf="transaction?.transaction_type === creditTransaction">{{"PARENT_PORTAL.TRANSACTIONS.FROM" | translate}} {{transaction.sender}}</h4>
            <h4 class="label label-{{transaction?.status}}" *ngIf="transaction?.transaction_type === debitTransaction">{{transaction.toName}}</h4>
            <span *ngIf="transaction?.status === statusComplete" class="date">{{transactionDate}}</span>
            <span *ngIf="transaction?.status !== statusComplete" class="date">{{transactionDate}} - {{transaction?.status | capitalize}}</span>
        </div>
        <span class="amount amount-{{transaction?.status}}">
            <span class="credit" *ngIf="transaction?.transaction_type === creditTransaction || transaction?.transaction_type === refundTransaction; else isDebit">+${{transaction.amount | number:'1.2-2' }}</span>
            <ng-template #isDebit>
                <span>-${{transaction.amount | number:'1.2-2' }}</span>
            </ng-template>
        </span>
    </div>
</section>