export const AUTH_EVENTS = {
    VIEW_PARENT_START_CONTINUE : 'view_parent_start_continue',
    VIEW_PARENT_LOAD_INVITATION_CODE_ERROR : 'view_parent_load_invitation_code_error',
    VIEW_PARENT_START_TELL_ME_MORE : 'view_parent_start_tell_me_more',
    SELECT_PARENT_START_TELL_ME_MORE : 'select_parent_start_tell_me_more',
    SELECT_PARENT_START_GET_STARTED : 'select_parent_start_get_started',
    SELECT_PARENT_START_MOVE_STORY : 'select_parent_start_move_story',
    SELECT_PARENT_AUTH_SIGN_UP : 'select_parent_auth_signup',
    VIEW_PARENT_AUTH_SIGN_UP_SUCCESS : 'view_parent_auth_signup_success',
    VIEW_PARENT_AUTH_SIGN_UP_ERROR : 'view_parent_auth_signup_error',
    VIEW_PARENT_AUTH_LOGIN : 'view_parent_auth_login',
    SELECT_PARENT_AUTH_LOGIN : 'select_parent_auth_login',
    VIEW_PARENT_AUTH_LOGIN_SUCCESS : 'view_parent_auth_login_success',
    VIEW_PARENT_AUTH_LOGIN_ERROR : 'view_parent_auth_login_error',
    SELECT_PARENT_AUTH_VERIFY : 'select_parent_auth_verify',
    VIEW_PARENT_AUTH_VERIFY_SUCCESS : 'view_parent_auth_verify_success',
    VIEW_PARENT_AUTH_VERIFY_ERROR : 'view_parent_auth_verify_error',
    SELECT_PARENT_LOGOUT_SUCCESS: 'select_parent_log_out_success',
    SELECT_LOGIN_VERIFY_SEND_CODE_PHONE: "select_login_verify_send_code_phone",
    SELECT_LOGIN_VERIFY_SEND_CODE_EMAIL: "select_login_verify_send_code_email",
    VIEW_PARENT_SIGNUP: 'view_parent_signup',
    VERIFY_TOKEN: 'verify_token',
    TOKEN_EXPIRATION_DATE: 'token_expiration_date',
    TOKEN_EXPIRATION_ERROR: 'token_expiration_error',
    USER_UNAUTHORIZED: 'user_unauthorized',
    WAF_BLOCKED_ERROR: 'waf_blocked_error',
} as const;