export const CStatusKYC = {
    KYC_PENDING:     'pending',
    KYC_SUBMITTED:   'submitted',
    KYC_APPROVED:    'approved',
    KYC_IN_REVIEW:   'in_review',
    KYC_IDV_REQUESTED: 'idv_requested',
    KYC_IDV_APPROVED: 'idv_approved',
    KYC_DECLINED:    'declined',
    NONE: 'NONE'
} as const;

export const STORAGE_KYC_FULL_ADDRESS = 'parentKYCFullAddress'
export const STORAGE_KYC_DATA = 'parentDataKYC'
export const STORAGE_KYC_ADDRESSES_FORM_FIELDS = 'addressesKYCFields'
export const STORAGE_PERSONA_REDIRECTED = 'personaRedirected';

export const AddressFormFieldsKYC = {
    ADDRESS_LINE_1:     'addressLine1',
    ADDRESS_LINE_2:   'addressLine2',
    HAS_NO_DETAILS: 'hasNoDetails',
    POSTAL_CODE:    'postalCode',
    CITY:   'city',
    STATE: 'state',
    AGREE: 'agree',
    ACCEPT: 'accept'
} as const;

export const CKycForms = {
    ADDRESS: 'address',
    PERSONAL: 'personal'
} as const;

export const C_WEB_VIEW_HELP_CENTER= 'HELP_CENTER' as const