import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  OnInit,
  Output,
  Input,
  ElementRef,
  ViewChild,
  inject,
} from "@angular/core";
import { ICountryCodes } from "./interfaces/country-input.inteface";
import { phone as E164Phones } from "phone";
import Countries from "@assets/CountriesCodes.json";

import {
  C_NUMBERS_ALLOWED_DEV,
  KEYBOARD_KEY_CODE,
  INPUT_PHONE_NUMBER_MASK,
  PhoneNumberRegExp,
  TYPE_INPUT_NUMERIC,
  USA_COUNTRY_CODE,
} from "./constants/country.constants";
import { TranslateService } from "@ngx-translate/core";
import { MessageService } from "primeng/api";
import { SECOND } from "@shared/models/constants/time.constant";
import { TOAST_SEVERITY_TYPES } from "@shared/primeng/constants/toast.constants";
import { C_TOAST_KEYS } from "@shared/primeng/constants/toast.keys";
import { environment } from "src/environments/environment";

@Component({
  selector: "mdk-country-phone-input",
  templateUrl: "./country-phone-input.component.html",
  styleUrls: ["./country-phone-input.component.scss"],
})
export class CountryPhoneInputComponent implements OnInit {
  @ViewChild("phoneInput", { static: true }) phoneInput!: ElementRef;

  //#region [---- INPUT ----]
  @Input() allowedCountries?: string[] = [];
  @Input() setPhoneNumber: string;
  @Input() isDisabled: boolean = false;
  @Input() heightActive: boolean = true;
  @Input() backgroundDisabled: boolean = false;

  //Placeholders and labels
  @Input() placeholderCountry: string;
  @Input() placeholderPhone: string;
  @Input() errorPhoneLabel: string;
  @Input() customErrorLabel: string;
  //#endregion

  //#region [---- OUTPUTS ----]
  @Output() outputPhone: EventEmitter<string> = new EventEmitter();
  //#endregion

  //#region [---- PROPERTIES ----]
  private preloadedCountryCode: string;
  private preloadedNumber: string;
  public onlyFirstCountry: boolean = false;

  private readonly USAcountryIndex = Countries.findIndex(
    (country) => country.code == USA_COUNTRY_CODE
  );
  public selectedCountry: ICountryCodes = Countries[this.USAcountryIndex];
  public countries!: ICountryCodes[];
  public phoneNumberValue!: string;
  public readonly phoneNumberMask = INPUT_PHONE_NUMBER_MASK;

  // Validate input
  public phoneIsInvalid: boolean = false;
  //#endregion

  //#region [---- DEPENDENCIES ----]
  private readonly translate: TranslateService = inject(TranslateService);
  private readonly cdRef: ChangeDetectorRef = inject(ChangeDetectorRef);
  private readonly messageService: MessageService = inject(MessageService);
  //#endregion

  //#region [---- LIFE CYCLES ----]
  ngOnInit(): void {
    this.loadTranslates();
    this.setInputNumeric();
    this.loadCountries();
    this.getPreloadPhoneNumber();
  }
  //#endregion

  //#region [---- LOGIC ----]
  private setInputNumeric(): void {
    const inputElement = this.phoneInput.nativeElement as HTMLInputElement;
    inputElement.inputMode = TYPE_INPUT_NUMERIC;
  }

  private loadCountries(): void {
    this.countries = Countries;
    this.filterCountries();
  }

  private filterCountries(): void {
    if (this.allowedCountries?.length > 0) {
      const filteredCountries = this.countries.filter((country) =>
        this.allowedCountries.includes(country.code)
      );

      if (filteredCountries?.length === 1) {
        this.selectedCountry = filteredCountries[0];
        this.onlyFirstCountry = true;
      } else if (filteredCountries?.length > 1) {
        this.countries = filteredCountries;
      }
    }
  }

  // key press validate
  public validateInput(event: KeyboardEvent) {
    if (
      (event.which != KEYBOARD_KEY_CODE.BACKSPACE_KEY && event.which != KEYBOARD_KEY_CODE.FUNCTION_KEYS && event.which < KEYBOARD_KEY_CODE.ZERO_KEY) ||
      event.which > KEYBOARD_KEY_CODE.NINE_KEY
    )
      event.preventDefault();
  }

  // CTRL + V, validate only numbers
  public async copyPasteValidate(event: KeyboardEvent) {
    if (
      (event.ctrlKey || event.metaKey) &&
      event.keyCode == KEYBOARD_KEY_CODE.V_KEY &&
      !!event.key
    ) {
      event?.preventDefault();

      if (navigator && navigator.clipboard) {
        try {
          const clipBoardValue = await navigator.clipboard.readText();
          if (clipBoardValue) {
            const result = clipBoardValue.replace(PhoneNumberRegExp.DIGITS, "");
            if (result) {
              this.phoneNumberValue = this.phoneNumberValue
                ? `${this.phoneNumberValue}${result}`
                : `${result}`;
            }
          }
        } catch (error) {
          this.messageService.add({
            life: SECOND * 5,
            severity: TOAST_SEVERITY_TYPES.WARNING,
            summary: this.translate.instant(
              "PHONE_COMPONENT.NOT_ALLOWED_PERMISSIONS"
            ),
            key: C_TOAST_KEYS.BOTTOM_CENTER,
          });
        }
      }
    }
  }

  // Click right and paste, validate only numbers
  public async paste(event: ClipboardEvent) {
    const clipBoardValue = event?.clipboardData?.getData("text");
    const onlyNumbers = PhoneNumberRegExp.NUMBER_PASTE.test(clipBoardValue);
    if (!onlyNumbers) event?.preventDefault();

    const result = clipBoardValue?.replace(PhoneNumberRegExp.DIGITS, "");
    if (result)
      this.phoneNumberValue = this.phoneNumberValue
        ? `${this.phoneNumberValue}${result}`
        : `${result}`;
  }

  // backspace and validate
  public backSpaceValidate(event: KeyboardEvent): void {
    const { target } = event;
    if (target) (target as HTMLButtonElement).value;

    if (
      event.keyCode === 8 ||
      ((event.ctrlKey || event.metaKey) && event.keyCode == 86)
    )
      this.validatePhoneForE164(this.phoneNumberValue);
  }

  private cleanMask(value: string): string {
    const digits = value.replace(PhoneNumberRegExp.DIGITS, '');
    const maskChars = this.phoneNumberMask.replace(PhoneNumberRegExp.MASK_CHARS, '');
    for (const char of maskChars) {
      digits.replace(char, '');
    }
    return digits;
  }

  public onInput(event: Event) {
    const target = event.target as HTMLInputElement;
    if (target instanceof HTMLInputElement) {
      const transformedValue = this.cleanMask(target.value);
      this.phoneNumberValue = transformedValue;
    }
  }

  // validate by E161 STANDARD
  public validatePhoneForE164(phoneNumber?: string): void {
    if (!phoneNumber) this.phoneIsInvalid = false;

    const phoneValue = this.phoneNumberValue || phoneNumber;
    const finalNumber: string = `${this.selectedCountry?.dial_code}${phoneValue}`;
    const isNumberAllowed: boolean = environment.production
      ? false
      : C_NUMBERS_ALLOWED_DEV.includes(finalNumber);
    const isValid: boolean = E164Phones(finalNumber, {
      country: this.selectedCountry?.code,
    }).isValid;

    if (isValid || isNumberAllowed) {
      this.phoneIsInvalid = false;
      this.emitPhoneValue(finalNumber);
    } else {
      this.phoneIsInvalid = true;
      this.emitPhoneValue("");
    }

    this.cdRef.detectChanges();
  }

  private getPreloadPhoneNumber(): void {
    if (this.setPhoneNumber) {
      const dataNumber = E164Phones(this.setPhoneNumber);
      this.preloadedCountryCode = dataNumber.countryCode;
      this.preloadedNumber = dataNumber.phoneNumber.substring(
        this.preloadedCountryCode.length
      );

      // Set default in component
      const indexCountryPreloadedISO = this.countries.findIndex(
        (country) => country.code === dataNumber.countryIso2
      );
      const indexCountryPreloadedCode = this.countries.findIndex(
        (country) => country.code === dataNumber.countryCode
      );

      this.selectedCountry = this.countries[indexCountryPreloadedISO] || this.countries[indexCountryPreloadedCode];
      this.phoneNumberValue = this.preloadedNumber;
    }
  }

  private emitPhoneValue(finalNumber: string): void {
    this.outputPhone.emit(finalNumber);
  }

  private loadTranslates(): void {
    if (!this.placeholderCountry)
      this.placeholderCountry = this.translate.instant(
        "PHONE_COMPONENT.COUNTRY"
      ); //Select a Country
    if (!this.placeholderPhone)
      this.placeholderPhone = this.translate.instant("PHONE_COMPONENT.LABEL");
    if (!this.errorPhoneLabel)
      this.errorPhoneLabel = this.translate.instant(
        "FORM.ERROR_MESSAGES.PHONE"
      );
  }
  //#endregion
}
